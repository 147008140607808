import * as React from "react";
import Layout from "../../components/layout";
import { Col, Row } from "react-bootstrap";
import TopImage from "../../components/topImage";

const StutteringPage = () => {
  return (
    <Layout>
      <TopImage imageName="training" />
      <Row>
        <Col>
          <h1 className="mb-4">Online Speech Training</h1>
          <p>
            Besides our continued efforts to gather more knowledge about
            stuttering and its causes, we also offer our help based on what we
            already know. Many years of scientific research have given us a very
            deep understanding of all kinds of stuttering. We apply the methods
            that we have developed based on the insights we have gotten so far
            to help you improve your fluency.
          </p>
          <p>
            As you might have already read on our website, we neither see
            stuttering as a disease nor as a disability. It is much more likely
            that stuttering is a unconsciously trained behavior, a learned
            behavior, which can be unlearned and changed with further training.
            Therefore, we don’t offer any kind of “therapy” - no, we offer
            training. A training that helps people to become fluent. And - of
            course - as we are a non-profit organization, the speech training
            will be completely free for you.
          </p>
          <p>
            We are now able to perform the complete training online - with no
            need for in-person meetings at all.
          </p>

          <h2 className="mt-5 mb-3">The Method</h2>
          <p>
            Our speech training tackles your specific stuttering patterns. This
            means that your training will be specifically tailored for your
            needs and your form of stuttering, based on the patterns that we
            have identified for you.
          </p>
          <p>The training consists of the following phases:</p>
          <h3 className="mt-5 mb-3">Phase 1: Stutter Profiling</h3>
          <p>
            We profile your specific kind of stuttering, its severity and its
            occurrences. This is the basic categorization so we can cross-check
            with our statistical documentation to find out which kind of
            training is likely to be the most effective for your case. At the
            end of the profiling, we will have a quantitative profile of your
            stuttering.
          </p>
          <p>
            During this phase, you will mainly fill out some online forms that
            help us to gather the necessary statistical data. Depending on your
            case, this process can consist of multiple steps of data gathering.
            In some cases, we also do some short interviews.
          </p>
          <h3 className="mt-5 mb-3">Phase 2: Pattern Identification</h3>
          <p>
            Our pattern identification specialists work with you to identify
            your specific stuttering patterns. Every person who stutters has
            unique stuttern patterns, therefore it is important to be very
            precise when identifying these. At the end of this phase, we will
            have a pattern profile of your stuttering which is the foundation
            for the actual speech training.
          </p>
          <p>
            During this phase, you will talk to one of our pattern
            identification experts. The identification process depends on your
            specific case and can range from interviews to speech exercises.
          </p>

          <h3 className="mt-5 mb-3">Phase 3: Basic Training</h3>
          <p>
            With the help of the quantitative profile and the pattern profile,
            we can develop a specific training plan for you. This training plan
            usually consists of multiple one-on-one meetings with your speech
            coach. It depends on your specific case, how many of these training
            sessions are needed. Sometimes, only a handful are already
            sufficient, sometimes it takes a few months. If everything works out
            as planned, you will be fluent - or at least nearly fluent - after
            your basic training.
          </p>

          <h3 className="mt-5 mb-3">Phase 4: Extended Training</h3>
          <p>
            After you have completed your basic training, you will observe your
            fluency over the following months. If you experience any decrease in
            your fluency, you can come back any time for additional training
            sessions.
          </p>

          <h2 className="mt-5 mb-3">The Results</h2>
          <p>
            Success takes time, discipline and effort. That’s true for anything
            that you want to become good at - sports, art, playing an
            instrument, etc. - and it is also true for speaking. But if you
            stick to the training schedule, the chances for being successful are
            very high. Some numbers:
          </p>

          <ul>
            <li>
              92 % of all participants describe themselves as stutter-free
              immediately after their basic training has been completed.
            </li>
            <li>
              85 % of all participants describe themselves still as stutter-free
              after at least 12 months after training.
            </li>
          </ul>

          <h2 className="mt-5 mb-3">Are you ready?</h2>
          <p>
            Contact us now at{" "}
            <a href="mailto: stutter-relief@beauty-foundation.com?subject=I am interested in speech training">
              stutter-relief@beauty-foundation.com
            </a>{" "}
            to get into your training program - for free. We promise you will
            never have to pay us for anything. And of course, the training can
            be completely anonymous - we don’t need your home address, we don’t
            need your name. We just need an email address where we can contact
            you.
          </p>
          <p>Our training is available in various languages.</p>
        </Col>
      </Row>
    </Layout>
  );
};

export default StutteringPage;
